import { INCENTIVE_STATUS } from "@/constants";

export type BudgetType = {
  createdAt: string;
  id: number;
  paidOutAmounts: number;
  remainingBudget: number;
  reservedFunds: number;
  totalBudget: number;
  updatedAt: string;
};

export enum TransferActionType {
  paid = "paid",
  reserved = "reserved",
  remaining = "remaining",
}

export type TransactionType = {
  id: number;
  amount: number;
  transaction_type: string;
  type: "add" | "deduct" | "transfer";
  note: string | null;
  total: number;
  budgetId: number;
  applicationId: number | null;
  createdAt: string;
  updatedAt: string;
  BudgetId: number;
  ApplicationId: number | null;
  from: "add" | "deduct" | "transfer";
  to: "add" | "deduct" | "transfer";
  email: string;
};

export type TransactionsDataType = { [key: string]: TransactionType[] };

export interface MonthData {
  Reserved: number;
  Paid: number;
  Remaining: number;
}

export interface AnalysisData {
  January: MonthData;
  February: MonthData;
  March: MonthData;
  April: MonthData;
  May: MonthData;
  June: MonthData;
  July: MonthData;
  August: MonthData;
  September: MonthData;
  October: MonthData;
  November: MonthData;
  December: MonthData;
}

export interface UserType {
  hashedPassword: string;
  verificationCode: string;
  role: "administrator" | "contractor" | "oem";
  status:
    | "approved"
    | "pending_approval"
    | "suspended"
    | "passed_training"
    | "deleted";
  lastLogin: string;
  rememberMe: boolean;
  id: number;
  fullName: string;
  email: string;
  resetPasswordToken: string | null;
  trainingCompleted: boolean;
  avatar: string | null;
  failedAttempts: number;
  accountBlocked: string | null;
  manufactoringBrand: string;
  contactEmail: string;
  contactName: string;
  contactPhone: string;
  dermsList: string | null;
  apiIntegrations: string | null;
  ieeeProtocol: boolean;
  maintainingTelemetryDataInterval: string;
  achInformation: string;
  companyName: string | null;
  businessLicenseNumber: string | null;
  companyAddress: string | null;
  companyWebsite: string | null;
  welcomeMessage: string | null;
  certification?: FileRecord | null;
  license?: FileRecord | null;
  createdAt: string;
  updatedAt: string;
  files?: FileRecord[];
  battery: Battery[];
}

interface UserBatteries {
  createdAt: string;
  updatedAt: string;
  batteryId: number;
  userId: number;
}

interface Battery {
  id: number;
  batteryModel: string;
  batteryType: string;
  batteryLocations: string;
  batteryCapacity: string;
  inverterModels: string | null;
  createdAt: string;
  updatedAt: string;
  UserBatteries: UserBatteries;
}

export interface LogType {
  accountName: string;
  createdAt: string;
  endpoint: string;
  email: string;
  role: UserType["role"];
  id: number;
  ipAddress: string;
  location: string;
  message: string;
  note: string;
  status: string;
  timestamp: string;
  updatedAt: string;
  logType: string;
  userId?: number; // Optional since it's empty in the given data
}

export interface SessionType {
  createdAt: string;
  id: number;
  ipAddress: string;
  location: string;
  status: string;
  updatedAt: string;
  user: number;
  userAgent: string;
}

export interface FileRecord {
  accountEmail: string;
  accountName: string | null;
  createdAt: string;
  deletedAt: string | null;
  fileName: string;
  id: number;
  key: string;
  mimeType: string;
  size: string;
  status: string;
  type: string;
  updatedAt: string;
  url: string;
  timeReviewd?: string;
  timeSubmited?: string;
  serialNumber: string | null;
  quarterlyPerformanceIncentiveValue: string | null;
  userId: number;
}

export interface ProjectRecord {
  id: number;
  userId: number;
  customerName: string;
  address: string;
  city: string;
  zipCode: string;
  incentiveStatus: INCENTIVE_STATUS | undefined;
  enrolmentStatus:
    | "active"
    | "pending"
    | "archived"
    | "correction_needed"
    | "rejected"
    | "contractor_updated"
    | null;
  batteryOem: string;
  batteryModel: string;
  upfrontIncentivePayment: string;
  batteryType: string;
  contractor: string | null;
  existingInverter: string;
  solarKwdc: string;
  solarKwac: string;
  solarAnnualProduction: string;
  newSolarKwdc: string;
  newSolarKwac: string;
  newSolarAnnualProduction: string;
  usableCapacity: string;
  numberOfBatteries: string;
  summerEnrolment: string;
  winterEnrolment: string;
  participation: "weekdays" | "weekends" | "weekdays_weekends";
  accountHash: string;
  accountEmail: string;
  note: string | null;
  createdAt: string;
  updatedAt: string;
  approvalDate: string | null;
  deletedAt: string | null;
  participantEnrollment: FileRecord;
  contract: FileRecord;
  systemSizingTool: FileRecord;
  comment: NoteType;
  latestEnrolmentUpdate: string | null;
  latestIncentiveUpdate: string | null;
  oemId: number | null;
  contractorId: number | null;
}

export type TemplateRowType = {
  id: number;
  fileName: string;
  fileSize: string;
  deletedAt: string | null;
  createdAt: string;
  fileType: string;
  accountName: string;
  accountEmail: string;
  url: string;
};

export type CookiesBody = {
  essentialCookies: true;
  performanceCookies?: boolean;
  functionalCookies?: boolean;
  targetingCookies?: boolean;
};

export type ArticleType = {
  id: number;
  title: string;
  content: string;
  createdAt: string;
  dateStamp: string;
};

export type NoteType = {
  id: number;
  note: string;
  userId: UserType["id"];
  projectId: ProjectRecord["id"];
  userName: string;
  createdAt: string;
  updatedAt: string;
};
